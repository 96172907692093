import Slider from "@mui/material/Slider";
import sliderone from "../../../../assets/images/home/image 9.png";
import slidertwo from "../../../../assets/images/home/image 9 (1).png";
import slidethree from "../../../../assets/images/home/image 10.png";
import slidefour from "../../../../assets/images/home/image 10 (1).png";
import bannervideo from "../../../../assets/images/home/home_banner_video.gif";
import contactus from "../../../../assets/images/home/contactButton.svg";
import contactusmobile from "../../../../assets/images/home/contactButtonMobile.svg";
import delniebanner from "../../../../assets/images/home/delnie-banner.png";
import "./index.css";

import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

const Carousel: React.FC = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <>
      <div className="container-fluid index-page-part1 banner">
        <div className="row home_centered">
          <h1 className="home_page_title">
            Transforming <br /> Challenges
            <br /> Into Innovations
          </h1>
          <p className="home_page_sub_title">
            Accelerating Growth With Innovative And <br /> Personalized Software
            Solutions
          </p>
        </div>
        <div className="row contact_button_image_row">
          {/* <a
            href="contact_us"
            className="contact_us_btn"
            rel="noopener noreferrer"
          >
            <img src={contactus} alt="" className="contact_button_image" />
          </a> */}
        </div>
      </div>
      <div className="container-fluid index-page-part2 banner">
        <div className="row home_centered_head">
          <h1>
            Transforming <br /> Challenges
            <br /> Into Innovations
          </h1>
          <p className="banner-subtitle">
            Accelerating Growth With Innovative And Personalized Software
            Solutions
          </p>
        </div>

        <div className="row contact_button_image_row">
          <div className="col-12 contact_button_image_col">
            {/* <a
              href="contact_us"
              className="contact_us_btn"
              rel="noopener noreferrer"
            >
              <img
                src={contactusmobile}
                alt=""
                className="contact_button_image img-fluid"
              />
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
