import React, { useState } from "react";
import { post } from "../../../../utils/api";
import questions from "./questions"; // Adjust the path based on the location of the JSON file

interface Question {
  id: number;
  label: string;
  type: "text" | "textarea" | "select" | "number" | "tel" | "date";
  name: string;
  multiple?: boolean;
  options?: { label: string; image?: string }[];
}

const questionsConfig: Question[] = questions;

const DynamicEnquiryForm: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [error, setError] = useState<string | null>(null);
  const [thankYou, setThankYou] = useState(false);
  const totalSteps = questionsConfig.length;

  const currentQuestion = questionsConfig[step - 1];

  const handleChange = (name: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const gtag_report_conversion = (url?: string) => {
    const callback = () => {
      if (typeof url !== "undefined") {
        window.location.href = url;
      }
    };
    // Type assertion to let TypeScript know that gtag exists in the window object
    (window as any).gtag("event", "conversion", {
      send_to: "AW-16707017413/wUrjCJ324vkZEMW1w54-", // Replace with your conversion ID and label
      value: 1.0,
      currency: "INR",
      event_callback: callback,
    });
    return false;
  };

  const handleMultipleSelect = (name: string, value: string) => {
    setFormData((prevData) => {
      const currentValues = prevData[name] || [];
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((val: string) => val !== value)
        : [...currentValues, value];
      return { ...prevData, [name]: updatedValues };
    });
  };

  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.phone_number || !formData.email) {
      setError("Please provide your contact information.");
      return;
    }

    setError(null);
    try {
      await post(`delnie_enquiries`, {
        ...formData,
        enquiry_data: currentQuestion,
      });
      setFormData({});
      setThankYou(true);
      gtag_report_conversion();
    } catch (error) {
      console.error(error);
      setError("Failed to submit your request. Please try again.");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f4f6f9",
        height: "100vh",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "600px",
          width: "100%",
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "0px 8px 20px rgba(0,0,0,0.1)",
          fontFamily: "Arial, sans-serif",
        }}
      >
        {thankYou ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <h2 style={{ color: "#28a745", marginBottom: "10px" }}>
              Thank You!
            </h2>
            <p style={{ fontSize: "16px", color: "#555" }}>
              Your enquiry has been successfully submitted. Our team will
              contact you shortly to discuss your project.
            </p>
            <p style={{ fontSize: "14px", color: "#888" }}>
              If you have any further questions, feel free to reach out to us at
              info@delnie.com
            </p>
          </div>
        ) : (
          <>
            <div
              style={{
                height: "8px",
                backgroundColor: "#e0e0e0",
                borderRadius: "4px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  width: `${(step / totalSteps) * 100}%`,
                  backgroundColor: "#007bff",
                  height: "100%",
                  borderRadius: "4px",
                }}
              ></div>
            </div>

            <h2
              style={{
                color: "#333",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Website Design Enquiry
            </h2>

            <div style={{ marginBottom: "16px" }}>
              <label
                style={{ fontSize: "18px", fontWeight: "bold", color: "#555" }}
              >
                {currentQuestion.label}
              </label>

              {currentQuestion.type === "select" && currentQuestion.options && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "10px",
                  }}
                >
                  {currentQuestion.options.map((option) => (
                    <button
                      key={option.label}
                      type="button"
                      onClick={() =>
                        handleChange(currentQuestion.name, option.label)
                      }
                      style={{
                        backgroundColor:
                          formData[currentQuestion.name] === option.label
                            ? "#007bff"
                            : "#f9f9f9",
                        color:
                          formData[currentQuestion.name] === option.label
                            ? "#fff"
                            : "#333",
                        padding: "12px",
                        marginBottom: "10px",
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                        fontSize: "16px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        transition: "background-color 0.3s, color 0.3s",
                      }}
                    >
                      {option.image && (
                        <img
                          src={option.image}
                          alt={option.label}
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "10px",
                            borderRadius: "4px",
                          }}
                        />
                      )}
                      {option.label}
                    </button>
                  ))}
                  {/* Display input field if "Other" is selected */}
                  {formData[currentQuestion.name] === "Other" && (
                    <input
                      type="text"
                      placeholder="Please specify"
                      value={formData[`${currentQuestion.name}_other`] || ""}
                      onChange={(e) =>
                        handleChange(
                          `${currentQuestion.name}_other`,
                          e.target.value
                        )
                      }
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "10px",
                        borderRadius: "8px",
                        border: "1px solid #ddd",
                        fontSize: "16px",
                      }}
                    />
                  )}
                </div>
              )}

              {["text", "tel"].includes(currentQuestion.type) && (
                <input
                  type={currentQuestion.type}
                  name={currentQuestion.name}
                  value={formData[currentQuestion.name] || ""}
                  onChange={(e) =>
                    handleChange(currentQuestion.name, e.target.value)
                  }
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginTop: "10px",
                    borderRadius: "8px",
                    border: "1px solid #ddd",
                    fontSize: "16px",
                  }}
                />
              )}
            </div>

            {error && (
              <p style={{ color: "red", marginTop: "10px" }}>{error}</p>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              {step > 1 && (
                <button
                  type="button"
                  onClick={prevStep}
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "#333",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                  }}
                >
                  Back
                </button>
              )}
              {step == 1 && (
                <button
                  type="button"
                  disabled={true}
                  //   onClick={prevStep}
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "#333",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    cursor: "pointer",
                  }}
                >
                  Back
                </button>
              )}
              {step < totalSteps ? (
                <button
                  type="button"
                  onClick={nextStep}
                  style={{
                    backgroundColor: "#007bff",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Continue
                </button>
              ) : (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: "#28a745",
                    color: "#fff",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DynamicEnquiryForm;
