interface Question {
  id: number;
  label: string;
  type: "text" | "textarea" | "select" | "number" | "tel" | "date";
  name: string;
  multiple?: boolean;
  options?: { label: string; image?: string }[];
}

const questions: Question[] = [
  {
    id: 1,
    label: "What are you looking to achieve with your website project?",
    type: "select",
    name: "projectType",
    options: [
      { label: "Launch a new website" },
      { label: "Redesign an existing website" },
    ],
  },
  {
    id: 2,
    label: "What is the primary purpose of your website?",
    type: "select",
    name: "websitePurpose",
    options: [
      { label: "Promote my business or services" },
      { label: "Sell products or services (e-commerce)" },
      { label: "Provide information or resources" },
      { label: "Other (e.g., personal blog, portfolio)" },
    ],
  },
  {
    id: 3,
    label: "What is your approximate budget for this project?",
    type: "select",
    name: "budgetRange",
    options: [
      { label: "₹25,000 - ₹49,999" },
      { label: "₹50,000 - ₹99,999" },
      { label: "₹1,00,000 - ₹1,99,999" },
      { label: "₹2,00,000 - ₹2,99,999" },
      { label: "₹3,00,000 - ₹4,99,999" },
      { label: "₹5,00,000 or more" },
      { label: "I’d like to discuss this with the professional" },
    ],
  },
  {
    id: 5,
    label: "How likely are you to make a hiring decision soon?",
    type: "select",
    name: "hiringIntent",
    options: [
      { label: "I'm ready to hire now" },
      { label: "I'm definitely going to hire someone" },
      { label: "I'm likely to hire someone" },
      { label: "I will possibly hire someone" },
      { label: "I'm planning and researching" },
    ],
  },
  {
    id: 6,
    label: "Please provide your Name for further communication.",
    type: "text",
    name: "name",
  },
  {
    id: 7,
    label: "Please provide your phone number for further communication.",
    type: "tel",
    name: "phone_number",
  },
  {
    id: 8,
    label: "Lastly, could you share your email address to receive our response?",
    type: "text",
    name: "email",
  },
];
export default questions;
