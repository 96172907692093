import React from "react";
import "./index.css";
import DelnieCloudAdmin from "../../../../assets/images/recent_work/delnie_cloud_admin.png"; // Adjust path to your actual image location

const HomeCloudAdmin = () => {
    return (
        <>
            <div className="home-cloud-admin-container">
                <div className="content-section">
                    <h1 className="title">Delnie Cloud Admin</h1>
                    <p className="description">
                        Delnie Cloud Admin is expertly designed to efficiently manage master
                        data, providing centralized data management that enhances
                        collaboration across all employees. With robust role-level
                        restrictions, it ensures security and access control tailored to
                        your organization's needs. The platform allows for seamless API
                        creation, streamlining operations and integrating effortlessly with
                        MongoDB Charts for real-time analytics and insightful
                        visualizations. Experience the power of a fully customized admin
                        panel, crafted to meet your unique business requirements, enabling
                        easy analysis through intuitive Mongo charts and empowering your
                        team with actionable insights.
                    </p>
                    <div className="row enquiry_link_row">
                        <a
                            href="/contact_us"
                            rel="noopener noreferrer"
                            className="cloud_admin_enquire"
                        >
                            Enquire Now{" "}
                            {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
          > */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="29"
                                viewBox="0 0 30 29"
                                fill="none"
                                style={{ display: "inline" }}  // Use an object for the style prop
                            >
                                <path
                                    d="M29.0361 1.99953C29.0361 1.1212 28.3241 0.40917 27.4458 0.40917L13.1325 0.409169C12.2542 0.409169 11.5422 1.1212 11.5422 1.99953C11.5422 2.87786 12.2542 3.58989 13.1325 3.58989L25.8554 3.58989L25.8554 16.3128C25.8554 17.1911 26.5674 17.9031 27.4458 17.9031C28.3241 17.9031 29.0361 17.1911 29.0361 16.3128L29.0361 1.99953ZM3.12456 28.5699L28.5703 3.12409L26.3212 0.874975L0.875445 26.3208L3.12456 28.5699Z"
                                    fill="black"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
                <div className="image-section">
                    <img src={DelnieCloudAdmin} alt="Delnie Cloud Admin" />
                </div>
            </div>
        </>
    );
};

export default HomeCloudAdmin;
