import "./index.css";
import One from "../../../../assets/images/squad/one.png";
import SQUAD from "../../../../assets/images/squad/SQUAD.png";

const data = [
  {
    title: "Creating the best-in-class products",
    description:
      "We put a lot of effort and passion into the products we build. We build products that our squad is proud of, not for the sake of completing a project.",
  },
  {
    title: "Learning never stops",
    description:
      "As our World constantly evolves, we understand the importance of not just keeping up but being one step ahead. If you are someone who likes to learn new technologies, better not skip us.",
  },
  {
    title: "Happy Squad = Happy Clients",
    description:
      "We know how to take care of our squad well, and we make sure they are in a happy environment. We believe that the product you create is the reflection of your environment.",
  },
];

function WhyJoinUs() {
  return (
    <div className="container-fluid squad_department">
      <div className="row why-delnie">
        <h1 className="whydel">Why join us?</h1>
      </div>
      <div className="row service">
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="row industry">
            <h1 className="indust_text section-title">
              An Opportunity to Explore the Real World Challenges
            </h1>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 squad_industry">
          <div>
            {data.map((item, index) => (
              <div key={index} className="row industry_service">
                <h1 className="create">{item.title}</h1>
                <p className="project">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    
    </div>
  );
}
export default WhyJoinUs;
