import "./index.css";
function HomeOurServices() {
  return (
    <div className="container-fluid what_we_provide_page">
      <div className="row wwp_heading_row">
        <h1>Our services</h1>
      </div>
      <div className="row wwp_para_row">
        <p>

          Delnie offers comprehensive digital solutions tailored for diverse business needs, specializing in mobile app development for iOS and Android platforms, website creation for mobile and desktop interfaces, and custom web applications, including React admin panels. Our mobile apps combine intuitive design with robust functionality, ensuring a standout presence in the app stores. Our websites are crafted to be visually appealing and optimized for SEO, enhancing your online presence across all devices. For web applications, we leverage the latest technologies to deliver powerful backend management and scalable solutions that improve operational efficiency and adapt to your evolving business requirements. </p>

      </div>
      <div className="row wwp_service_row">
        <div className="col-lg-4 col-sm-6 wwp_service_col">
          <h1>Mobile Apps</h1>
          <p>(iOS/Android)</p>
        </div>
        <div className="col-lg-3 col-sm-6 wwp_service_col wwp_service_col_3">
          <h1>Websites</h1>
          <p>(Mobile/Desktop)</p>
        </div>
        <div className="col-lg-4 col-sm-12 wwp_service_col">
          <h1>Web applications</h1>
          <p>(Custom React Admin panels and others)</p>
        </div>
      </div>
    </div>
  );
}
export default HomeOurServices;
